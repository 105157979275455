
import logo from '../logo.svg';

const Header = () => {
    return (
        <div className="w-full h-52 bg-sky-500/50 flex items-center">            
            <img src={logo} className="h-3/4 pl-6" alt="logo" />
            <a href='/' className='pl-6 text-5xl'>
                Sakura Interactive
            </a>
        </div>
    );
}

export default Header;